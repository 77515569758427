@import '../../assets/styles/index';

.emptyData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 76%;
  text-align: center;
  gap: 20px;

  svg {
    width: 40px;
    height: 40px;
  }
}

.msg {
  white-space: pre-line;
  text-align: center;
  line-height: 30px;
  width: 80%;
}
