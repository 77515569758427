@import '../colors/colors';
@import '../typography/_typography';
@import '../mixins/themes';

@mixin modalFieldsStyle {
  @include flexStyleColumn;
  width: 100%;
  gap: 15px;
}

@mixin fieldContainerStyle {
  @include flexStyleColumn;
  gap: 10px;
}

@mixin modalInputStyles {
  box-shadow: none;
  @include modalInputTextFont;

  &:hover {
    border-color: $ButtonHoverColor;
  }
  &:focus {
    border-color: $ButtonHoverColor;
  }

  &::placeholder {
    @include modalPlaceHolderFont;
  }
}

@mixin modalLabelStyles {
  @include modalLabelFont;
  color: $secondaryFontColor;
}

@mixin multiplyOptionStyles {
  display: flex;
  align-items: center;
  gap: 10px;

  .memberName {
    @include modalPlaceHolderFont;
  }
}
