@import '../../assets/styles/_index';

.modalDropdown {
  @include componentShadow;
  background-color: white;
  position: relative;
  padding: 15px;
  border-radius: 15px;
  z-index: 1000;

  &.small {
    width: 100px;
    max-height: 100px;
  }

  &.medium {
    width: 450px;
    min-height: 200px;
    max-height: 400px;
  }

  &.large {
    width: 700px;
    max-height: 400px;
  }
  .content {
    overflow-y: auto;
    min-height: 200px;
    max-height: 350px;
  }
}
