@import '../../../assets/styles/_index';

.container {
  @include componentShadow;
  @include flexStyleColumn;
  height: auto;
  width: 40%;
  border-radius: 15px;
  background-color: white;
  flex-grow: 1;

  .topContainer {
    @include flexStyleColumn;
    gap: 1rem;
    padding: 1rem;

    .title {
      @include titleFont;
    }

    .subTitle {
      @include subTitleFont;
      color: $secondaryFontColor;
    }
  }

  .hrBorder {
    @include hrBorderStyle;
  }

  .midWrapper {
    height: 100%;
    overflow: auto;

    .midContainer {
      @include flexStyleColumn;
      gap: 0.5rem;
      padding: 1rem;
      height: 100%;
      overflow: scroll;
    }
  }

  .bottomContainer {
    @include flexStyleRow;
    justify-content: space-between;
    padding: 1rem;

    &.alignSelfEnd {
      align-self: flex-end;
    }

    .addButton {
      @include subTitleFont;
      @include flexStyleRow;
      align-items: center;
      cursor: pointer;

      &:active {
        transform: scale(0.98);
      }
    }
  }
}

.dropdownItem {
  @include flexStyleRow;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
