@import '../../assets/styles/index';

.searchContainer {
  @include flexStyleRow;
  justify-content: center;
  align-items: center;
  background-color: $hoverBackground;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  gap: 10px;
  height: 40px;
  padding: 5px;
  padding-left: 15px;

  .searchInput {
    @include h3Font;
    width: 100%;
    height: 100%;
  }
}
