@font-face {
  font-family: outfitRegular;
  src: url('../fonts/Outfit-Regular.ttf');
}
@font-face {
  font-family: outfitSemiBold;
  src: url('..//fonts/Outfit-SemiBold.ttf');
}
@font-face {
  font-family: outfitBlack;
  src: url('./Outfit-Black.ttf');
}
@font-face {
  font-family: outfitBold;
  src: url('./Outfit-Bold.ttf');
}
@font-face {
  font-family: outfitExtraBold;
  src: url('./Outfit-ExtraBold.ttf');
}
@font-face {
  font-family: outfitExtraLight;
  src: url('./Outfit-ExtraLight.ttf');
}
@font-face {
  font-family: outfitLight;
  src: url('./Outfit-Light.ttf');
}
@font-face {
  font-family: outfitMedium;
  src: url('./Outfit-Medium.ttf');
}
@font-face {
  font-family: outfitThin;
  src: url('./Outfit-Thin.ttf');
}
