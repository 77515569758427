@import '../../../assets/styles/index';

.fieldContainer {
  @include fieldContainerStyle;

  .ModalInput {
    @include modalInputStyles;
  }
  .memberOption {
    @include multiplyOptionStyles;

    .memberName {
      @include modalPlaceHolderFont;
    }
  }

  .modalLabelText {
    @include modalLabelStyles;
  }
}
