@import '../../assets/styles/_index';

.backButton {
  @include flexStyleRow;
  @include backButtonFont;
  cursor: pointer;
  gap: 0.25rem;
  align-items: center;
  align-self: flex-start;
  width: auto;
}
