@import '../../assets/styles/index';

.button {
  @include flexStyleRow;
  cursor: pointer;
  padding: 15px;
  align-items: center;
  height: 40px;
  background-color: $ButtonColor;
  border-radius: 10px;
  gap: 10px;
  white-space: nowrap;
  transition: background-color ease 0.3s;

  &:hover {
    background-color: $ButtonHoverColor;
  }

  &:active {
    background-color: $ButtonPressColor;
    transform: scale(0.98);
  }

  &:disabled {
    background-color: $ButtonDisableColor;
    cursor: not-allowed;
  }

  .buttonTitle {
    @include ButtonFont;
    color: $ButtonFontColor;
  }
}

.secondary {
  background-color: $secondaryButtonColor;
  border: 1px solid $ButtonColor;

  &:hover {
    background-color: $secondaryButtonHoverColor;
  }

  &:active {
    background-color: $secondaryButtonActiveColor;
    transform: scale(0.98);
  }
  &:disabled {
    background-color: transparent;
    border-color: $ButtonDisableColor;
    color: $ButtonDisableColor;
    cursor: not-allowed;
  }
  .buttonTitle {
    @include ButtonFont;
    color: $ButtonColor;

    &.disabled {
      color: $ButtonDisableColor;
    }
  }
}

.danger {
  background-color: $dangerButtonColor;
  border: none;

  &:hover {
    background-color: $dangerButtonHoverColor;
  }

  &:active {
    background-color: $dangerButtonActiveColor;
    transform: scale(0.98);
  }
  &:disabled {
    background-color: $dangerButtonDisableColor;
  }
  .buttonTitle {
    @include ButtonFont;
    color: white;
  }
}
