@import '../../../assets/styles/_index';

.wizardInput {
  width: 100%;
  border: 1px solid $borderColor;
  border-radius: 10px;
  height: 2.5rem;
  padding: 1rem;
  transition: border-color ease 0.3s;
  &:hover {
    border-color: $ButtonHoverColor;
  }
  &::placeholder {
    opacity: 0.5;
  }
}
