@import '../../assets/styles/index';

.statusBox {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;

  &.pending {
    @include statusStyle($pendingBackgroundColor, $pendingColor);
  }

  &.completed {
    @include statusStyle($completedBackgroundColor, $completedColor);
  }

  &.inProgress {
    @include statusStyle($inProgressBackgroundColor, $inProgressColor);
  }

  &.drafts {
    @include statusStyle($draftBackgroundColor, $draftColor);
  }

  &.discovery {
    @include statusStyle($discoveryBackgroundColor, $discoveryColor);
  }

  &.competitiveAnalysis {
    @include statusStyle($competitiveAnalysisBackgroundColor, $competitiveAnalysisColor);
  }

  &.messagingEngine {
    @include statusStyle($messagingEngineBackgroundColor, $messagingEngineColor);
  }

  &.internal {
    @include statusStyle($internalBackgroundColor, $internalColor);
  }

  &.external {
    @include statusStyle($externalBackgroundColor, $externalColor);
  }
  &.client {
    @include statusStyle($externalBackgroundColor, $externalColor);
  }
}
