@import '../../assets/styles/_index';

.layoutWrapper {
  @include flexStyleRow;
  @include layout-gradient;
  height: 100vh;
  padding: 30px 30px 30px 50px;

  .content {
    transition:
      margin-left 0.4s ease,
      width 0.4s ease;
  }
  &.expanded {
    .content {
      margin-left: 200px;
      width: calc(100% - 200px);
    }
  }

  &.collapsed {
    .content {
      margin-left: 60px;
      width: calc(100% - 60px);
    }
  }
  &.guidelineLayout {
    @media (max-height: 1240px) {
      height: 100%;
    }
  }
}
