@import '../../../../../assets/styles/_index';

.detailsModalContainer {
  @include flexStyleColumn;
  gap: 20px;
  margin-top: 10px;

  .boxContainer {
    @include flexStyleColumn;
    gap: 10px;

    .title {
      color: $secondaryFontColor;
      @include wizardTabFont;
    }
    .text {
      @include wizardTextFont;
    }
    .link {
      color: $blueLinkColor;
      cursor: pointer;
    }
  }
}
