/********** General Colors **********/
$greyColor: #f2f5f7; /* Light Grey Background */
$greyBorder: #e3e6e9; /* Grey Border */
$lightBlue: #004488; /* Highlight Blue */
$softGrey: #81809a; /* Soft Grey */
$borderColor: #c7cdd3; /* Grey border color */
$whiteColor: white; /* Regular White */
$hoverBackground: #f9f8f6; /* Hover Color */
$paginationHover: #eeece7; /* Pagination Hover Color */
$blueLinkColor: #0078d4; /* Blue Link Color */

/********** Typography Colors **********/
$primaryFontColor: #333333; /* Default Font Color */
$secondaryFontColor: #71787f; /* Secondary Font Color */

/********** Project Status Colors **********/
// Pending
$pendingColor: #da902d; /* Text Color */
$pendingBackgroundColor: #ffe5c1; /* Background Color */

// Completed
$completedColor: #1fad70; /* Text Color */
$completedBackgroundColor: #e9f7f1; /* Background Color */

// In Progress
$inProgressColor: #0078d4; /* Text Color */
$inProgressBackgroundColor: #c4e7f5; /* Background Color */

// Draft
$draftColor: #b3b3c2; /* Text Color */
$draftBackgroundColor: #e3e6e9; /* Background Color */

/********** Step Badge Colors **********/
// Discovery
$discoveryColor: #0078d4;
$discoveryBackgroundColor: #c4e7f5;

// Competitive Analysis
$competitiveAnalysisColor: #da902d;
$competitiveAnalysisBackgroundColor: #ffe5c1;

// Messaging Engine
$messagingEngineColor: #1fad70;
$messagingEngineBackgroundColor: #e9f7f1;

// Internal
$internalColor: #6867b2;
$internalBackgroundColor: #e8ebf7;

// External
$externalColor: #da902d;
$externalBackgroundColor: #ffe5c1;

/********** Utility Colors **********/
$successColor: #28a745; /* General Success Color */
$errorColor: #dc3545; /* General Error Color */
$warningColor: #ffc107; /* General Warning Color */
$infoColor: #17a2b8; /* General Info Color */

/********** Button Colors **********/
$ButtonColor: #8e8df4; /* Button Background */
$ButtonHoverColor: #807fdc; /* Button Hover Background */
$ButtonPressColor: #6867b2; /* Button Press Background */
$buttonHrColor: #6262e1; /* Button hr under line color*/
$backButtonColor: #5f18f5; /* Back Button Color */
$ButtonFontColor: #eff9fa; /* Button Font */
$ButtonDisableColor: #b3b3c2; /* Button Disable Background */
$secondaryButtonColor: #ffffff;
$secondaryButtonHoverColor: #f6f5fa; /* Secondary Button Active */
$secondaryButtonActiveColor: #f2f1f8; /* Secondary Button Active */
$dangerButtonColor: #d44b58;
$dangerButtonHoverColor: #94353e;
$dangerButtonActiveColor: #6a262c;
$dangerButtonDisableColor: #eeb7bc;

/********** Action Button Colors **********/
$actionButtonBackground: #f7f6f4; /* Button Background */
$actionButtonHover: #eeece7; /* Button Hover Background */
$actionButtonActive: #dfdcd2; /* Button Press Background */

/********** Label Tags Colors **********/
$defaultLabelColor: #e8ebf7; /* Label Background */
$labelHoverColor: #e7e9f4; /* Label Hover Background */
$labelPressColor: #e4e7f0; /* Label Press Background */
$labelDisableColor: #fcfdfd; /* Label Disable Background */
$labelFontColor: #6867b2; /* Label Font */

/********** Avatar Colors **********/
$avatarBackgroundColor: #cdd3db; /* Avatar Background */
$avatarTextColor: #24466e; /* Avatar Text */

/********** Badge Colors **********/
$completedBackgroundColor: #e9f7f1;
$completedFontColor: #1fad70;
$warningColor: #d5a706;

/********** Wizard Colors **********/
$aiQuestionPurple: #ececfd;
$stepGreenVi: #16794e;
$logoGreyColor: #929aa3;
