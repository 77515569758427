@import '../../../assets/styles/_index';

.wizardHeader {
  @include flexStyleColumn;
  gap: 1rem;

  .headerContainer {
    @include flexStyleRow;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    position: relative;

    .title {
      @include flexStyleRow;
      gap: 0.7rem;
    }

    .tabs {
      @include wizardTabFont;
      @include flexStyleRow;
      gap: 1.5rem;
      align-items: center;

      .tab {
        @include wizardTabFont;
        cursor: pointer;
        white-space: nowrap;
      }
      .membersTab {
        @include flexStyleRow;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        cursor: pointer;
      }
      .membersMenuContainer {
        @include flexStyleRow;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}

.modalsContainer {
  position: absolute;
  top: 15px;
  right: 0;
  margin-top: 30px;
}

.menuIcon {
  cursor: pointer;
}
.dropdownItem {
  @include flexStyleRow;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.names {
  @include flexStyleRow;
  gap: 0.6rem;
  align-items: center;
  .clientTab {
    @include wizardTextFont;
    white-space: nowrap;
    opacity: 0.5;
  }
  .businessUnitTab {
    @include wizardTextFont;
    white-space: nowrap;
    opacity: 0.5;
  }
  .projectTab {
    @include wizardTabFont;
    white-space: nowrap;
  }
  .tab {
    opacity: 0.5;
    white-space: nowrap;
  }
}

.statusDropDown {
  @include flexStyleColumn;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.statusContainer {
  @include flexStyleRow;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.spinner {
  height: 100%;
}

.downArrowIcon {
  transition: transform 0.2s ease;
  transform: rotate(0deg);

  &.open {
    transform: rotate(180deg);
  }
}
