@import '../../assets/styles/index';

.businessUnitContainer {
  @include flexStyleColumn;
  gap: 1rem;
  height: 100%;

  .title {
    @include h1Font;
  }
  .topContainer {
    @include flexStyleRow;
    align-items: center;
    justify-content: space-between;
  }
  .bottomContainer {
    @include flexStyleColumn;
    flex-grow: 1;
    height: 100%;
  }
}

.wrapper {
  @include flexStyleColumn;
  flex-grow: 1;
  height: 100%;
  gap: 1rem;
}

.tabsContainer {
  display: flex;
  flex-grow: 1;
}

.dropdownItem {
  @include flexStyleRow;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
