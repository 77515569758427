@import '../../assets/styles/index';

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: auto;

  &.commandCenter {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }

  .arrow {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: background-color ease 0.3s;

    &:hover {
      background-color: $paginationHover;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .paginationContent {
    margin: 0;
    opacity: 0.5;
    font-size: 16px;
  }
}
