@import '../../assets/styles/index';

.refreshButton {
  @include flexStyleRow;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;
  transition: background-color ease 0.3s;

  &:hover {
    border-radius: 50%;
    background-color: $actionButtonHover;
  }
  &:active {
    border-radius: 50%;
    background-color: $actionButtonActive;
  }
}
