@import '../../../../assets/styles/index';

.userOptionContainer {
  @include flexStyleRow;
  align-items: center;
  gap: 10px;
}
.avatarUser {
  @include avatarNameFont;
  height: 20px;
  width: 20px;
  background-color: $avatarBackgroundColor;
  color: $avatarTextColor;
}
.avatarUserName {
  @include avatarNameFont;
}
