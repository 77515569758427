@import '../../assets/styles/index';

.actionButton {
  @include flexStyleRow;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $actionButtonBackground;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 2px 2px 2px #00000040;
  transition: background-color ease 0.3s;

  &:hover {
    background: $actionButtonHover;
  }

  &:active {
    background: $actionButtonActive;
  }

  .icon {
    width: 10px;
    height: 10px;
    transition: transform ease 0.7s;
    &.flipped {
      transform: rotate3d(0, 1, 0, 180deg);
    }
  }
}
