@import '../../assets/styles/index';

.clientsContainer {
  @include flexStyleColumn;
  height: 100%;
  .title {
    @include h1Font;
    margin-bottom: 30px;
  }

  .topContainer {
    @include flexStyleRow;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .bottomContainer {
    @include flexStyleColumn;
    flex-grow: 1;
    gap: 1rem;
  }
}
