@mixin flexStyleRow {
  display: flex;
  flex-direction: row;
}

@mixin flexStyleColumn {
  display: flex;
  flex-direction: column;
}

@mixin statusStyle($backgroundColor, $textColor) {
  background-color: $backgroundColor;
  color: $textColor;
}

@mixin buttonFunctions {
  cursor: pointer;
  margin: 0;
  background: none;
}

@mixin componentShadow {
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
}

@mixin tableBorder {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

@mixin responsive-min-height {
  @include breakpoint-medium() {
    min-height: 850px;
  }
  @include breakpoint-large() {
    min-height: 1000px;
  }
}

@mixin hrBorderStyle {
  background-color: $secondaryFontColor;
  height: 1px;
  opacity: 0.3;
}
