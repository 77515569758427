@import '../../../../assets/styles/_index';

.tab {
  @include flexStyleRow;
  gap: 0.25rem;
  align-items: center;
  cursor: pointer;
}

.title {
  @include wizardTabFont;
  white-space: nowrap;

  &.bold {
    font-weight: 600;
  }
}
