@import '../../assets/styles/_index';

.sidebar {
  @include sidebar-gradient;
  @include componentShadow;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom-right-radius: 15px;
  transition:
    width 0.4s ease,
    padding 0.4s ease;

  &.expanded {
    width: 220px;
  }

  &.collapsed {
    width: 80px;
  }

  .sidebarWrapper {
    @include flexStyleColumn;
    flex-grow: 1;
    position: relative;

    .logo {
      @include flexStyleColumn;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;

      .hrBorder {
        @include hrBorderStyle;
        margin-top: 20px;
        background-color: $secondaryFontColor;
        height: 1px;
        opacity: 0.3;
        width: 80%;
        margin-left: 7px;

        &.expanded {
          width: 90%;
        }
      }

      .logoImg {
        width: 60px;
        height: 15px;
        transition: all 0.3s ease;
        margin-bottom: 7.5px;
        margin-top: 7.5px;

        &.expanded {
          width: 100px;
          height: 30px;
          padding-left: 20px;
          transition:
            width 0.5s ease,
            padding 0.5s ease,
            transform 0.4s ease;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .nav {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;

      .topLinksContainer {
        @include flexStyleColumn;
        gap: 10px;
        padding-left: 5px;
      }

      .bottomLinksContainer {
        @include flexStyleColumn;
        gap: 10px;
        margin-top: auto;
      }
    }

    .actionButtonOpen {
      position: absolute;
      top: 80%;
      left: 98%;
    }
  }
}
