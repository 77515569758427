@import '../../../assets/styles/_index';

.WizardAIAssistant {
  @include componentShadow;
  @include flexStyleColumn;
  background-color: white;
  position: absolute;
  justify-content: center;
  bottom: 5%;
  right: 10%;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  z-index: 100000;
  gap: 15px;
  transition: all ease 0.3s;
  animation: fade-in 0.5s ease-out;

  .topContainer {
    @include flexStyleRow;
    justify-content: space-between;
    align-items: center;

    .buttonBackground {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 0.3s;

      &:hover {
        background-color: $actionButtonHover;
      }
      &:active {
        background-color: $actionButtonActive;
      }
    }

    .title {
      @include flexStyleRow;
      gap: 0.3rem;
      .titleText {
        @include competitiveItemFont;
      }
    }
  }
  .hrBorder {
    @include hrBorderStyle;
  }
  .midContainer {
    @include flexStyleColumn;
    flex-direction: column-reverse;
    overflow-y: auto;
    max-height: 250px;

    .conversationBlock {
      @include flexStyleColumn;
      gap: 15px;

      .aiQuestion {
        @include AIAssistantText;
        padding: 10px;
        border-radius: 10px;
        align-self: flex-end;
        background-color: $aiQuestionPurple;
      }
      .aiAnswer {
        @include AIAssistantText;
        padding: 10px;
        align-self: flex-start;
      }
      .copyText {
        @include flexStyleRow;
        @include copyAnswerFont;
        cursor: pointer;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
  .bottomContainer {
    @include flexStyleRow;
    @include componentShadow;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;

    .input {
      width: 100%;
      height: 100%;

      &::placeholder {
        @include AIAssistantText;
        color: #929aa3;
        opacity: 1;
      }
    }
  }
}
