@import '../../../../assets/styles/index';

.fieldInput {
  @include wizardTabFont;
  padding: 5px;
  border: 1px solid $ButtonHoverColor;
  border-radius: 10px;
  width: 100%;
}

.fieldTab {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  white-space: nowrap;
}
