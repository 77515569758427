@import '../../../assets/styles/_index';

.container {
  @include componentShadow;
  position: relative;
  border-radius: 15px;
  background-color: white;
  width: 60%;
  flex-grow: 1;
  height: 100%;

  .customToolbar {
    @include flexStyleRow;
    gap: 0.5rem;
  }

  .topContainer {
    @include flexStyleColumn;
    justify-content: space-between;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;

    .toolbarContainer {
      @include flexStyleRow;
      justify-content: space-between;
      gap: 1rem;

      .rowsTableContainer {
        @include flexStyleRow;
      }
    }

    .badgesContainer {
      @include flexStyleRow;
      gap: 0.5rem;
      padding-top: 0.75rem;
    }

    .buttonsContainer {
      @include flexStyleRow;
      gap: 0.5rem;
    }
  }
}

.dropdownItem {
  @include flexStyleRow;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.adjustToneContainer {
  @include flexStyleRow;
  border-radius: 1rem;
  padding: 10px;
  width: 400px;
  gap: 2rem;
  z-index: 10000;
}

.loaderContainer {
  justify-self: center;
  align-self: center;
}

.spinner {
  height: 100%;
}
