@import '../../../../assets/styles/index';

.searchContainer {
  @include flexStyleRow;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid $ButtonHoverColor;
  border-radius: 10px;
  gap: 10px;
  height: 40px;
  padding: 5px;
  padding-left: 15px;

  .searchInput {
    @include h3Font;
    width: 100%;
    height: 100%;
  }

  .dropdown {
    @include flexStyleColumn;
    @include componentShadow;
    gap: 20px;
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    padding: 15px;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;

    &.show {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  .userOption {
    cursor: pointer;
    background-color: white;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: $hoverBackground;
    }
  }
}
