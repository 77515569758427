@import '../../../assets/styles/index';

.fontContainer {
  @include flexStyleRow;
  gap: 30px;

  .fontPreview {
    width: auto;
    height: 100px;
    font-size: 5rem;
    font-weight: 900;
  }
  .fontSample {
    width: auto;
    height: auto;
    font-size: 1rem;
    text-align: left;

    .fontName {
      font-weight: 900;
    }
  }
}

.loaderStyling {
  color: $ButtonColor;
}

.text1 {
  @include guideLinesInputLabelFont;
  opacity: 0.7;
}

.text2 {
  @include guideLinesInputLabelFont;
  opacity: 0.5;
}

.draggerContainer {
  @include flexStyleColumn;
  align-items: center;
  gap: 10px;
}
