@mixin sidebar-gradient {
  background: linear-gradient(250deg, rgba(247, 246, 244, 1), rgba(242, 227, 227, 1), rgba(184, 194, 219, 1));
}

@mixin layout-gradient {
  background: linear-gradient(
    155deg,
    rgb(255, 255, 255) 0%,
    rgb(253, 253, 253) 50%,
    rgba(242, 227, 227, 1) 80%,
    rgba(184, 194, 219, 1) 100%
  );
}
