@import './colors/colors';

.ant-input {
  @include modalInputTextFont;
  box-shadow: none !important;
  transition: all ease 0.3s !important;

  &:hover {
    border-color: $ButtonHoverColor;
  }
  &:focus-within {
    border-color: $ButtonHoverColor;
  }
}

.dropdown-modal {
  .ant-select-selector {
    &:hover {
      border-color: $ButtonHoverColor !important;
    }
    &:focus {
      border-color: $ButtonHoverColor !important;
    }
  }

  &.ant-select-focused .ant-select-selector {
    border-color: $ButtonHoverColor !important;
    box-shadow: none !important;
  }
}

.rc-virtual-list-holder-inner {
  gap: 0.5rem;
}

.rc-virtual-list-holder {
  gap: 0.5rem;
}

.ant-select-item-option-selected {
  background-color: none !important;
}
.ant-select-item-option-active {
  background-color: none !important;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-select-outlined.ant-select-multiple
  .ant-select-selection-item {
  background-color: $defaultLabelColor !important;
  color: $labelFontColor;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $ButtonColor;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-tab {
  &:hover {
    color: $ButtonPressColor;
  }
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-tab-remove:active {
  color: $ButtonPressColor;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-tab-btn {
  &:hover {
    color: $ButtonPressColor;
  }
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-ink-bar {
  background-color: $buttonHrColor;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}

.dropdown-modal .ant-select-selection-placeholder {
  @include modalPlaceHolderFont;
}

.ant-select-item.ant-select-item-option[aria-selected='false'] {
  background-color: transparent !important;
}

.ant-select-item.ant-select-item-option[aria-selected='true'] {
  background-color: $defaultLabelColor !important;
}

.ant-upload-drag {
  &:hover {
    border-color: $ButtonHoverColor !important;
  }
}

.ant-color-picker-trigger {
  border: dashed !important;
  box-shadow: none !important;
}

.ant-upload {
  &:hover {
    border-color: $ButtonHoverColor !important;
  }
}

:where(.css-dev-only-do-not-override-1ludfr9, .css-1ludfr9).ant-progress
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: $ButtonColor !important;
}

:where(.css-dev-only-do-not-override-1ludfr9, .css-1ludfr9).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #e9f7f1 !important;
}

:where(.css-dev-only-do-not-override-1ludfr9, .css-1ludfr9).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #16794e !important;
  background-color: #16794e !important;
}

:where(.css-dev-only-do-not-override-6i5tsc, .css-6i5tsc).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #e9f7f1 !important;
}

.ant-collapse.ant-collapse-icon-position-end:focus-within,
.ant-collapse.ant-collapse-icon-position-end .ant-collapse-item:focus-within {
  border-color: $ButtonHoverColor;
  outline: none;
}

.ant-collapse.ant-collapse-icon-position-end,
.ant-collapse.ant-collapse-icon-position-end .ant-collapse-item {
  transition: border 0.2s ease-in-out;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-collapse {
  background-color: white;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-error {
  height: 3rem;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(
    .ant-btn-disabled
  ):hover,
:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: $ButtonHoverColor;
  border-color: $ButtonHoverColor;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-upload-wrapper .ant-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: $ButtonColor;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-tabs .ant-tabs-ink-bar {
  background-color: $buttonHrColor;
}

:where(.css-dev-only-do-not-override-6i5tsc, .css-6i5tsc).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: gray;
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-tabs .ant-tabs-tab {
  color: $softGrey;
  &:hover {
    color: $ButtonHoverColor;
  }
}

:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-1x0dypw, .css-1x0dypw).ant-tabs .ant-tabs-tab-remove:active {
  color: $ButtonPressColor;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-content {
  height: 100%;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-upload-wrapper .ant-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-collapse {
  background-color: white;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

.ql-editor strong {
  font-weight: bold !important;
}

.ql-editor u {
  text-decoration: underline !important;
}

.ql-editor em {
  font-style: italic !important;
}

.ql-editor s {
  text-decoration: line-through !important;
  overflow-y: auto !important;
  max-height: 600px !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border-radius: 15px !important;
  width: fit-content !important;
}

.ql-editor {
  overflow-y: auto !important;
  max-height: 420px !important;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select
  > .ant-upload,
:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select
  > .ant-upload {
  width: 100%;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-spin-nested-loading {
  height: 100%;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-tabs .ant-tabs-tabpane {
  height: 100%;
}

:where(.css-dev-only-do-not-override-6i5tsc, .css-6i5tsc).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: $stepGreenVi;
}

:where(.css-dev-only-do-not-override-6i5tsc, .css-6i5tsc).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: black;
}

:where(.css-dev-only-do-not-override-77tu7h, .css-77tu7h).ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}
