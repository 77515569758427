@import '../consts/consts';

// Medium screens: 1025px to 1440px
@mixin breakpoint-md() {
  @media (min-width: $screenMdMin) and (max-width: $screenLgMin) {
    @content;
  }
}

// Large screens: 1440px to 1920px
@mixin breakpoint-lg() {
  @media (min-width: $screenLgMin) and (max-width: $screenXlMin) {
    @content;
  }
}

// Extra large screens: 1920px to 3840px
@mixin breakpoint-xl() {
  @media (min-width: $screenXlMin) and (max-width: $screenXlMax) {
    @content;
  }
}

// Ultra-wide screens: 3840px and above
@mixin breakpoint-ultra-wide() {
  @media (min-width: $screenXlMax) {
    @content;
  }
}
