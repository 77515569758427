@import '../../../assets/styles/index';

.draggerContainer {
  @include flexStyleColumn;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .text1 {
    @include guideLinesInputLabelFont;
    opacity: 0.7;
  }

  .text2 {
    @include guideLinesInputLabelFont;
    opacity: 0.5;
  }
}

.logosContainer {
  @include flexStyleRow;
  border: 1px solid $borderColor;
  border-radius: 10px;
  padding: 30px;

  .plusIcon {
    cursor: pointer;
  }

  &:hover {
    border-color: $ButtonHoverColor;
    transition: all ease 0.3s;
    cursor: pointer;
  }

  .uploadButton {
    margin-top: 0.5rem;
  }
}
