@import '../../assets/styles/index';

.commandCenterContainer {
  @include flexStyleColumn;
  margin-top: 0.5rem;
  height: 100%;
  gap: 2rem;

  .title {
    @include h1Font;
  }
  .wrapper {
    @include flexStyleRow;
    height: 90%;
    gap: 30px;

    .secondWrapper {
      width: 65%;
      position: relative;
    }
  }
}
