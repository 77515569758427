@import '../../../../assets/styles/_index';

.topContainer {
  @include flexStyleRow;
  justify-content: space-between;
  align-items: center;

  .tabName {
    @include flexStyleRow;
    gap: 15px;
    align-items: center;
  }

  .buttonBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s;

    &:hover {
      background-color: $actionButtonHover;
    }
    &:active {
      background-color: $actionButtonActive;
    }
  }
}

.hrLine {
  border: 1px solid $greyBorder;
  margin-top: 10px;
}
