@import '../../../../assets/styles/_index';

.generalInformationContainer {
  @include flexStyleColumn;
  gap: 0.5rem;
  height: auto;

  .title {
    @include subTitleFont;
    color: $secondaryFontColor;
  }

  .nextButton {
    margin-top: 0.5rem;
    align-self: flex-end;
  }
}
