.ql-editor {
  flex-grow: 1;
  height: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.quill {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ql-container.ql-snow {
  flex: 1;
  height: 100%;
}

.quill .ql-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.quill .ql-editor {
  flex: 1;
  max-height: 85% !important;
}
