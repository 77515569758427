@import '../../../../../assets/styles/_index';

.toneOfVoiceContainer {
  @include flexStyleColumn;
  gap: 20px;
  margin-top: 10px;

  .boxContainer {
    position: relative;
    border: 1px solid $borderColor;
    border-radius: 15px;
    padding: 15px;
    min-height: 150px;
    margin-top: 10px;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: $ButtonHoverColor;
    }

    .cancelEdit {
      @include wizardTabFont;
      position: absolute;
      z-index: 1;
      bottom: 20px;
      right: 15px;
      cursor: pointer;
      color: $secondaryFontColor;
    }

    .text {
      @include wizardTextFont;
      width: 330px;
      height: 150px;
      border: none;
      background-color: white;
      outline: none;
      resize: none;
    }

    .buttonBackground {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
      margin-left: 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 0.3s;
      z-index: 1;

      &:hover {
        background-color: $actionButtonHover;
      }
      &:active {
        background-color: $actionButtonActive;
      }
    }
  }
  .hrBorder {
    @include hrBorderStyle;
  }
  .bottomContainer {
    @include flexStyleRow;
    justify-content: flex-end;
  }
}
