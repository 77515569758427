@import '../../../../assets/styles/_index';

.wrapper {
  @include flexStyleColumn;
  .container {
    @include flexStyleColumn;
    gap: 0.5rem;
    height: auto;

    .title {
      @include subTitleFont;
      color: $secondaryFontColor;
    }

    .buttonContainer {
      @include flexStyleRow;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 0.3rem;
    }
    .linkInputs {
      @include flexStyleColumn;
      gap: 1rem;

      .removeLink {
        color: red;
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
  .nextButton {
    margin-top: 0.5rem;
    align-self: flex-end;
  }
}

.uploadButtons {
  @include flexStyleRow;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.textExplanationContainer {
  @include flexStyleColumn;
  justify-content: flex-start;
  gap: 0.5rem;

  .textExplanationButton {
    display: contents;
    cursor: pointer;
    &:hover {
      color: $ButtonHoverColor;
    }
  }
  .textExplanationText {
    color: $secondaryFontColor;
  }
  .removeTextExplanation {
    color: red;
    opacity: 0.5;
    cursor: pointer;
  }
}

.attachFilesButton {
  cursor: pointer;
}

.uploadButtonsContainer {
  @include flexStyleColumn;
  gap: 0.2rem;
}
