@import '../../../../assets/styles/_index';

.container {
  @include flexStyleColumn;
  gap: 1rem;

  .title {
    color: $secondaryFontColor;
  }

  .inputsContainer {
    @include flexStyleColumn;
    gap: 1.5rem;

    .topInputs,
    .bottomInputs {
      @include flexStyleColumn;
      gap: 0.5rem;
    }
  }
  .keywordsContainer {
    @include flexStyleColumn;
    gap: 1rem;

    .keywordsTitle {
      color: $secondaryFontColor;
    }

    .keywordsList {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
    .keywordItem {
      display: inline-block;
      align-items: center;
      gap: 0.5rem;
      background-color: $defaultLabelColor;
      border-radius: 10px;
      padding: 5px;
      min-width: max-content;
      white-space: nowrap;
      transition: backGround-color ease 0.3s;

      &:hover {
        background-color: $labelHoverColor;
      }

      .keyword {
        color: $labelFontColor;
        padding-right: 0.5rem;
      }

      .alignItems {
        display: flex;
        align-items: center;
        gap: 0.3rem;

        .closeIcon {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .addKeyWordButton {
    @include subTitleFont;
    &:hover {
      cursor: pointer;
      color: $ButtonHoverColor;
    }
  }
  .inputContainer {
    @include flexStyleRow;
    align-items: center;
    gap: 1rem;
    .addButton {
      @include flexStyleRow;
      gap: 0.3rem;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.bottomContainer {
  @include flexStyleRow;
  align-self: flex-end;
  gap: 1rem;

  .removeButton {
    @include flexStyleRow;
    @include wizardTabFont;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    &:hover {
      cursor: pointer;
      transform: scale(0.99);
    }
    &:active {
      transform: scale(0.98);
    }
  }
}

.modalContainer {
  @include flexStyleColumn;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .titlesContainer {
    @include flexStyleColumn;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    .modalTitle {
      @include modalTitleFont;
    }
    .modalSubTitle {
      @include modalSecondTitleFont;
    }
  }

  .modalButtonsContainer {
    @include flexStyleRow;
    justify-content: center;
    gap: 1rem;
  }
}
