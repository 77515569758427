@import '../helpers';
@import '../fonts/fonts';

$outfitSemiBoldFont: 'outfitSemiBold', sans-serif;
$outfitRegular: 'outfitRegular', sans-serif;
$outfitMedium: 'outfitMedium', sans-serif;

@mixin h1Font {
  @include fontStyle(700, 20px);
}

@mixin h2Font {
  @include fontStyle(400, 18px, $outfitRegular);
}

@mixin h3Font {
  @include fontStyle(300, 14px);
}

@mixin ButtonFont {
  @include fontStyle(400, 14px, $outfitRegular);
}

@mixin backButtonFont {
  @include fontStyle(400, 16px, $outfitRegular);
}

/************** Table Fonts **************/

@mixin tableRowFont {
  @include fontStyle(300, 14px);
}

@mixin tableHeadlineFont {
  @include fontStyle(700, 14px, $outfitSemiBoldFont);
}

/************** News Item Fonts **************/

@mixin newsSourceNameFont {
  @include fontStyle(400, 12px);
}

@mixin newsContentFont {
  @include fontStyle(600, 16px, $outfitSemiBoldFont);
}

@mixin newsCompanyNameFont {
  @include fontStyle(700, 12px);
}

@mixin sideBarText {
  @include fontStyle(300, 14px);
}

/************** Modal Fonts **************/

@mixin modalTitleFont {
  @include fontStyle(400, 24px);
}

@mixin modalSecondTitleFont {
  @include fontStyle(300, 16px);
}

@mixin modalLabelFont {
  @include fontStyle(400, 14px);
}

@mixin modalPlaceHolderFont {
  @include fontStyle(300, 14px);
}

@mixin modalSaveButtonFont {
  @include fontStyle(400, 14px);
}

@mixin modalInputTextFont {
  @include fontStyle(400, 14px, $outfitRegular);
}

/************** Avatar Fonts **************/

@mixin avatarNameFont {
  @include fontStyle(300, 13px);
}

/************** Guidelines Fonts **************/

@mixin guideLinesTitleFont {
  @include fontStyle(500, 16px, $outfitMedium);
}

@mixin guideLinesInputLabelFont {
  @include fontStyle(400, 14px, $outfitRegular);
}

/************** Wizard Fonts **************/

@mixin wizardTabFont {
  @include fontStyle(400, 14px, $outfitRegular);
}

@mixin modalTitlesFont {
  @include fontStyle(500, 16px, $outfitMedium);
}

@mixin wizardTextFont {
  @include fontStyle(300, 14px);
}

@mixin wizardMembersTitleFont {
  @include fontStyle(400, 24px, $outfitRegular);
}

@mixin wizardMembersSecondTitleFont {
  @include fontStyle(400, 16px, $outfitRegular);
}

/************** Wizard Fonts **************/

@mixin titleFont {
  @include fontStyle(500, 16px, $outfitSemiBoldFont);
}

@mixin subTitleFont {
  @include fontStyle(100, 14px, $outfitMedium);
}

@mixin wizardMembersSecondTitleFont {
  @include fontStyle(400, 16px, $outfitRegular);
}

@mixin badgeFont {
  @include fontStyle(400, 12px, $outfitRegular);
}

@mixin competitiveItemFont {
  @include fontStyle(500, 14px, $outfitMedium);
}

@mixin AIAssistantText {
  @include fontStyle(300, 14px);
}

@mixin copyAnswerFont {
  @include fontStyle(400, 14px, $outfitRegular);
}
