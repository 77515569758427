@import '../../../../assets/styles/_index';

.wrapper {
  @include flexStyleColumn;
  .targetAudienceContainer {
    @include flexStyleColumn;
    gap: 0.5rem;
    height: auto;

    .title {
      @include subTitleFont;
      color: $secondaryFontColor;
    }
    .inputsContainer {
      @include flexStyleColumn;
      gap: 0.5rem;

      .secondAudienceButton {
        @include flexStyleRow;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        border: none;
        box-shadow: none;
        cursor: pointer;
        &:hover {
          color: $ButtonHoverColor;
        }
      }
    }
  }
  .nextButton {
    margin-top: 0.5rem;
    align-self: flex-end;
  }
}
