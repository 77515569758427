@import '../../../assets/styles/index';

.newsContainer {
  @include flexStyleColumn;
  .redirect {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    width: 100%;
    text-align: left;
  }
  .newsItemBackground {
    padding-inline: 25px;
    border-radius: 8px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $hoverBackground;
    }
    &:last-child {
      border-bottom: none;
    }
    .newsItem {
      @include flexStyleColumn;
      position: relative;
      width: 100%;
      border-bottom: 1px solid $greyBorder;

      .openDetailIcon {
        position: absolute;
        top: 40%;
        right: 0px;
      }

      .newsTextContainer {
        @include flexStyleColumn;
        margin-top: 10px;
        margin-right: 20px;
        gap: 10px;

        .itemName {
          @include newsSourceNameFont;
          color: $lightBlue;
          margin: 0;
        }

        .itemContent {
          @include newsContentFont;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          width: 90%;
          margin-bottom: 10px;
        }
      }

      .sourceDateContainer {
        @include flexStyleRow;
        @include newsSourceNameFont;
        color: $secondaryFontColor;
        margin-top: auto;
        align-items: center;
        gap: 10px;
        margin-right: 20px;
        margin-bottom: 15px;
      }
    }
  }
}
