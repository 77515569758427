@import '../../../assets/styles/index';

.navLink {
  margin: 0;
  border-radius: 5px;
  width: 100%;
  height: 20px;
  display: flex;
  padding: 20px;
  transition: background-color ease 0.3s;

  &:hover {
    background-color: $hoverBackground;
    cursor: pointer;
  }

  &.userOption {
    padding-left: 18px;
  }

  &:active {
    transform: scale(0.98);
  }

  &.expanded {
    transition:
      padding 0.4s ease,
      transform 0.4s ease;
  }
  &.selected {
    opacity: 1;
  }

  &:not(.selected) {
    opacity: 0.5;
  }
}

.iconTextContainer {
  @include flexStyleRow;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .icon,
  .staticLogo {
    @include flexStyleRow;
  }
}

.linkText {
  @include sideBarText;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-15px);
  pointer-events: none;
  transition:
    opacity 0.3s ease 0s,
    transform 0.3s ease 0s;

  &.hidden {
    opacity: 0;
    transform: translateX(-10px);
    pointer-events: none;
    transition:
      opacity 0.3s ease 0s,
      transform 0.3s ease 0s;
  }
  .navLink.expanded & {
    opacity: 1;
    transform: translateX(0);
    pointer-events: none;
    transition:
      opacity 0.3s ease 0.2s,
      transform 0.3s ease 0.2s;
  }
}
