@import '../../assets/styles/_index';

.wizardContainer {
  @include layout-gradient;
  height: 100vh;
  padding: 2rem 2.5rem 3rem;
  @include flexStyleColumn;
  gap: 1.5rem;

  .hrBorder {
    @include hrBorderStyle;
  }

  .midContainer {
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    align-items: center;

    .stepperContainer {
      grid-column: 2;
      justify-self: center;
      width: 100%;
    }

    .buttonsContainer {
      grid-column: 3;
      justify-self: end;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }

  .bottomContainer {
    @include flexStyleRow;
    flex-grow: 1;
    gap: 1.5rem;
    overflow: hidden;
  }
}

.spinner {
  height: 100%;
}
