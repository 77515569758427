@import '../../assets/styles/index';

.emptyData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  text-align: center;
  gap: 20px;

  .msg {
    white-space: pre-line;
    text-align: center;
    line-height: 30px;
  }
}
