@import '../../assets/styles/index';

.specificClientContainer {
  @include flexStyleColumn;
  height: 100%;

  .title {
    @include h1Font;
    margin-bottom: 15px;
  }
  .topContainer {
    @include flexStyleRow;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .bottomContainer {
    @include flexStyleColumn;
    flex-grow: 1;
    height: 100%;
  }
}
.wrapper {
  @include flexStyleColumn;
  flex-grow: 1;
  height: 100%;
  gap: 1rem;
}

.tabsContainer {
  height: 97%;
}
