@import '../../assets/styles/_index';

.topSection {
  @include flexStyleColumn;
  gap: 10px;

  .primaryTitle {
    @include modalTitleFont;
    white-space: pre-line;
    text-align: center;
  }
  .secondaryTitle {
    @include wizardMembersSecondTitleFont;
    align-self: center;
    text-align: center;
  }
}
.buttons {
  @include flexStyleRow;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
