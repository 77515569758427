@import '../../assets/styles/index';

.custom-modal {
  .ant-modal-content {
    border-radius: 5px;
    padding: 60px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
