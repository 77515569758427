@import '../../assets/styles/index';

.dataListContainer {
  @include flexStyleColumn;
  @include componentShadow;
  background-color: $whiteColor;
  padding: 25px;
  padding-top: 0;
  border-radius: 10px;
  min-width: 65%;
  height: 100%;
  animation: fade-in 0.8s ease-out;

  &.commandCenter {
    padding-top: 25px;
  }

  .listTopContainer {
    @include flexStyleRow;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .dataListTitle {
      @include h2Font;
    }
  }
}
