@import '../../assets/styles/index';

.projectModalTitle {
  @include modalTitleFont;
}
.modalFields {
  @include modalFieldsStyle;

  .fieldContainer {
    @include fieldContainerStyle;

    .projectsModalInput {
      @include modalInputStyles;
    }
    .memberOption {
      @include multiplyOptionStyles;

      .memberName {
        @include modalPlaceHolderFont;
      }
    }

    .modalLabelText {
      @include modalLabelStyles;
    }
  }
}
.projectModalFooter {
  display: flex;
  justify-content: center;

  .gifAnimation {
    height: 40px;
    width: 40px;
  }

  .projectModalSaveButton {
    @include modalSaveButtonFont;
    background-color: $ButtonColor;
    margin-top: 20px;
    width: 100%;

    &:hover {
      background-color: $ButtonHoverColor !important;
    }
    &:active {
      background-color: $ButtonPressColor !important;
    }
    &:disabled {
      background-color: $ButtonDisableColor !important;
    }
  }
}
.projectsModalInput::placeholder {
  @include modalPlaceHolderFont;
}
