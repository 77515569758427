@import '../../../assets/styles/_index';

.attachFilesButton {
  @include flexStyleRow;
  align-items: center;
  cursor: pointer;
}

.dropDownHeader {
  @include flexStyleRow;
  align-items: center;

  .completeBadge {
    @include flexStyleRow;
    width: 6rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    margin-left: auto;
    background-color: $completedBackgroundColor;
    border-radius: 10px;
    .badgeTitle {
      @include badgeFont;
      color: $completedFontColor;
    }
  }
}
