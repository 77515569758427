@import '../../../assets/styles/index';

.errorModalContainer {
  @include flexStyleColumn;
  margin-top: 40px;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  gap: 15px;

  .failureAnimation {
    width: 80px;
    height: 80px;
  }
  .errorFirstTitle {
    @include modalTitleFont;
    white-space: pre-line;
    text-align: center;
  }
  .errorSecondTitle {
    @include modalSecondTitleFont;
    margin-bottom: 20px;
  }
  .backButton {
    @include flexStyleRow;
    cursor: pointer;
    gap: 5px;
    justify-content: center;
    align-items: center;
    color: $backButtonColor;
  }
}
