@import '../../../assets/styles/_index';

.dropDownHeader {
  @include flexStyleRow;
  align-items: center;

  .competitorItem {
    @include competitiveItemFont;
  }
  .completeBadge {
    @include flexStyleRow;
    width: 6rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    margin-left: auto;
    background-color: $completedBackgroundColor;
    border-radius: 10px;
    .badgeTitle {
      @include badgeFont;
      color: $completedFontColor;
    }
  }
}

.addCompetitor {
  @include competitiveItemFont;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-left: 1rem;
  &:active {
    transform: scale(0.99);
  }
}
