@import '../../assets/styles/index';

.table {
  width: 100%;

  &.loading {
    height: 100%;
    &:hover {
      cursor: default;
      background-color: white;
    }
  }

  &.loading {
    height: 100%;
    &:hover {
      cursor: default;
      background-color: white;
    }
  }

  th,
  td {
    @include tableRowFont;
    @include tableBorder;
    text-align: left;
    transition: padding 0.3s ease;

    @include breakpoint-md() {
      padding: 0.3rem;
    }

    @include breakpoint-lg() {
      padding: 1rem;
    }

    @include breakpoint-xl() {
      padding: 1.125rem;
    }

    @include breakpoint-ultra-wide() {
      padding: 1.25rem;
    }
  }

  &.commandCenter {
    th,
    td {
      transition: padding 0.3s ease;

      @include breakpoint-md() {
        padding: 0.6rem;
      }

      @include breakpoint-lg() {
        padding: 0.8rem;
      }

      @include breakpoint-xl() {
        padding: 1.125rem;
      }

      @include breakpoint-ultra-wide() {
        padding: 1.5rem;
      }
    }
  }

  &.client {
    th,
    td {
      transition: padding 0.3s ease;

      @include breakpoint-md() {
        padding: 0.8rem;
      }

      @include breakpoint-lg() {
        padding: 1.3rem;
      }

      @include breakpoint-xl() {
        padding: 1.125rem;
      }

      @include breakpoint-ultra-wide() {
        padding: 1.25rem;
      }
    }
  }
  &.specificClient {
    th,
    td {
      transition: padding 0.3s ease;

      @include breakpoint-md() {
        padding: 0.6rem;
      }

      @include breakpoint-lg() {
        padding: 1rem;
      }

      @include breakpoint-xl() {
        padding: 1.15rem;
      }

      @include breakpoint-ultra-wide() {
        padding: 1.15rem;
      }
    }
  }

  th {
    border-bottom: none;
  }

  .tableHeadline th {
    @include tableHeadlineFont;
    @include tableBorder;
  }

  tbody tr {
    position: relative;
    &:hover {
      background-color: $hoverBackground;
      transition: background-color 0.3s ease;
      cursor: pointer;
    }

    &:last-child td {
      border-bottom: none;
    }
  }
}

.loaderRow {
  cursor: default;
  background-color: white !important;
  cursor: default !important;
}

.noResults {
  &:hover {
    cursor: default;
    background-color: white;
  }
}

.loaderCell {
  height: 100%;
}

.menuIcon {
  position: absolute;
  top: 0.6rem;
  right: 1rem;
}
