@import '../../../../../assets/styles/_index';

@mixin sectionContainer {
  @include flexStyleColumn;
  justify-content: center;
  gap: 10px;

  .borderContainer {
    border: 1px solid $borderColor;
    min-height: 100px;
    border-radius: 15px;
    padding: 15px;

    &.logosContent .logo,
    &.fontsContent .font,
    &.colorsContent .colorBox {
      padding: 15px;
    }
  }
}

.brandModalWrapper {
  @include flexStyleColumn;
  gap: 10px;
  margin-top: 10px;

  .logosContainer {
    @include sectionContainer;
    display: flex;

    .logosContent {
      @include flexStyleRow;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;

      .logo {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(30% - 20px);
        cursor: pointer;
        max-width: 110px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.8px solid #929aa3;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        .logoThumb {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          position: absolute;
        }
      }
    }
  }

  .fontsContainer {
    @include sectionContainer;

    .fontContainer {
      @include flexStyleRow;
      align-items: center;
      gap: 10px;

      .fontPreview {
        width: auto;
        font-size: 4rem;
        font-weight: 900;
      }
      .fontSample {
        width: auto;
        height: auto;
        font-size: 1rem;
        text-align: left;

        .fontName {
          font-weight: 900;
        }
      }
    }
  }

  .colorsPaletteContainer {
    @include flexStyleRow;
    border: 1px solid $borderColor;
    border-radius: 10px;
    padding: 20px;

    .colorsContent {
      @include flexStyleRow;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      height: 100%;
      .colorBox {
        flex: 1 1 20%;
        width: 70px;
        max-width: 70px;
        height: 70px;
        margin: 5px;
        border: 1px solid #ccc;
        border-radius: 10px;
      }
    }
  }
}

.title {
  @include modalTitlesFont;
}

.emptyState {
  @include sideBarText;
}
