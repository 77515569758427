@import '../../../../../assets/styles/_index';

.modalContainer {
  @include flexStyleColumn;
  gap: 15px;
  align-items: flex-start;

  .topContainer {
    @include flexStyleRow;
    justify-content: space-between;
    width: 100%;

    .titles {
      @include flexStyleColumn;
      align-self: flex-start;
      align-items: flex-start;
      gap: 5px;
    }
    .buttonBackground {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 0.3s;

      &:hover {
        background-color: $actionButtonHover;
      }
      &:active {
        background-color: $actionButtonActive;
      }
    }

    .title {
      @include wizardMembersTitleFont;
    }
    .secondTitle {
      @include modalSecondTitleFont;
      color: $secondaryFontColor;
    }
  }

  .midContainer {
    width: 100%;
  }

  .bottomContainer {
    @include flexStyleColumn;
    gap: 15px;
    min-height: 200px;
    padding: 5px;
    width: 100%;

    .userTag {
      @include flexStyleRow;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .closeIcon {
        cursor: pointer;
      }
    }
  }
}
