@import '../../assets/styles/index';

.ViewContainer {
  @include flexStyleColumn;
  @include componentShadow;
  justify-content: center;
  background-color: $whiteColor;
  margin-top: 10px;
  padding: 25px;
  border-radius: 10px;
  height: 100%;
  min-height: 700px;

  .saveButton {
    @include flexStyleRow;
    justify-content: flex-end;

    &.isParentGuideline {
      justify-content: space-between;

      .parentGuidelineFlag {
        @include h3Font;
        color: $warningColor;
      }
    }

    .parentGuidelineContainer {
      @include flexStyleRow;
      gap: 1rem;
      align-items: center;
    }
  }

  .guidelineFlag {
    @include modalSecondTitleFont;
    color: $blueLinkColor;
  }
}
.clientGuidelinesWrapper {
  @include flexStyleRow;
  gap: 70px;
  width: 100%;

  .title {
    @include guideLinesTitleFont;
    margin-bottom: 10px;
  }

  .leftContainer {
    @include flexStyleColumn;
    width: 40%;
    gap: 30px;

    .generalDetailsContainer {
      @include flexStyleColumn;

      .inputsContainer {
        @include flexStyleColumn;
        gap: 15px;

        .inputWrapper {
          @include flexStyleColumn;
          gap: 5px;

          .inputLabel {
            @include guideLinesInputLabelFont;
            opacity: 0.5;
          }
        }
      }
    }
  }
  .rightContainer {
    @include flexStyleColumn;
    width: 60%;
    gap: 30px;
  }
}
.error {
  color: red;
  opacity: 0.6;
}
