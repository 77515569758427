@import '../../../assets/styles/index';

.newsScroller {
  @include flexStyleColumn;
  @include componentShadow;
  width: 35%;
  background-color: $whiteColor;
  padding: 20px 10px 25px 0px;
  border-radius: 10px;

  .newsTopContainer {
    @include flexStyleRow;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 15px;

    .newsScrollerTitle {
      @include h2Font;
      margin: 15px 0 15px 0;
    }
  }

  .newsBox {
    overflow-y: auto;
    height: 100%;
  }
}
