@import '../../../assets/styles/index';

.colorPaletteContainer {
  .topContainer {
    @include flexStyleRow;
    justify-content: space-between;
    align-items: center;

    .title {
      @include guideLinesTitleFont;
      margin-bottom: 10px;
    }
  }

  .paletteContainer {
    @include flexStyleRow;
    flex-wrap: wrap;
    border: 1px solid $borderColor;
    border-radius: 10px;
    padding: 20px;
    gap: 30px;
    min-height: 130px;
    transition: all ease 0.3s;

    &:hover {
      border-color: $ButtonHoverColor;
      cursor: pointer;
    }
  }

  .colorItem {
    @include flexStyleColumn;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .colorBox {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }

  .addColor {
    display: flex;
    align-items: center;
  }
  .deleteButton {
    margin-top: 15px;
  }
}
